import React from 'react';
import MuiMenu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import "./style.scss";

const MenuItem = (props) => {
  const { label, icon, iconClass, textClass, color, primary, iconFont } = props;
  return (
    <React.Fragment>
      {
        Boolean(icon) &&
        <div className='icon row h-ctr'>
          <i className={`${iconFont || 'f8'} ${icon} ${iconClass || ''} ${!primary && (color || 'c00085')}`} />
        </div>
      }
      <span className={`reg f8 ${textClass || ''} ${!primary && (color || 'c00085')}`}>{label}</span>
    </React.Fragment>
  )
}


export const Menu = (props) => {
  const { anchorEl, menuItems, id } = props;
  return (
    <React.Fragment>
      <MuiMenu
        anchorEl={anchorEl}
        id={id || "account-menu"}
        open={Boolean(anchorEl)}
        onClose={props.onClose}
        onClick={props.onClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <div className='col menus'>
          {
            (menuItems || []).map((_, i) => {
              const isEmpty = Boolean(_.isEmpty)
              const className = `btn row item ${isEmpty && 'empty'} ${_.primary && 'primary'}`
              return (
                _.isLink ?
                  <Link to={_.to} className={className} key={i} >
                    <MenuItem {..._} />
                  </Link> :
                  <button key={i} className={className} disabled={_.disabled} onClick={(e) => !isEmpty && props.onMenuClick(e, _)}>
                    {!isEmpty && <MenuItem {..._} />}
                  </button>
              )
            })
          }
        </div>
      </MuiMenu>
    </React.Fragment>
  )
}