import React from 'react';
import { CKEditor } from 'ckeditor4-react';
import { AppErrorBoundry } from "../Screens";

const CustomCSS = `
  @import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
  .tbl-cont-line{
    width:100%;
    clear:both;
  }
  .tbl-cont-line .section{
    float:left;
  }
  .tbl-cont-line .pageno{
    float:right;
  }
`;
const getFontSizes = () => {
  let fonts = '';
  for (let i = 6; i <= 36; i++) {
    fonts += `${i}/${i}px;`
    if (i >= 10) {
      i++;
    }
  }
  fonts += '48/48px;60/60px;72/72px;90/90px;'
  return fonts;
}
const CKConfig = {
  toolbar: [
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
		{ name: 'colors', items: [ 'TextColor', 'BGColor' ] },
		{ name: 'styles', items: [ 'Font', 'FontSize' ] },
		{ name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
		{ name: 'paragraph', items: ['Outdent', 'Indent', '-',  'NumberedList', 'BulletedList' ] },
		{ name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak' ] },
		{ name: 'links', items: [ 'Link', 'Unlink' ] },
		{ name: 'editing', items: [ 'Find', 'Replace', '-', 'Scayt' ] },
		{ name: 'tools', items: [ 'Maximize'] }
  ],
  font_names: 'Roboto;Exo 2;',
  font_defaultLabel: 'Roboto',
  fontSize_defaultLabel: '14',
  font_style: {
    element: 'span',
    styles: { 'font-family': '"#(family)"' },
    overrides: [{ element: 'font', attributes: { 'face': null } }]
  },
  colorButton_backStyle: {
    element: 'span',
    styles: { 'background-color': '#(color)' }
  },
  colorButton_foreStyle: {
    element: 'span',
    styles: { color: '#(color)' }
  },
  removeButtons: 'Paste,PasteText,PasteFromWord,contextmenu',
  tabSpaces: 4,
  fullPage: false,
  resize_enabled: false,
  colorButton_enableMore: true,
  removePlugins: 'resize,elementspath',
  editorplaceholder: 'Start typing here...',
  // fontSize_sizes: '8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;18/18px;16/16px;24/24px;30/30px;32/32px;34/34px;36/36px;48/48px;60/60px;72/72px;90/90px;',
  fontSize_sizes: getFontSizes(),
  extraPlugins: 'autogrow,copyformatting,font,colorbutton,justify,contextmenu,editorplaceholder,pagebreak',
  contentsCss: CustomCSS,
  autoGrow_minHeight: 80,
  autoGrow_maxHeight: 4800
}
const InitialData = `<span style="font-family:Roboto; font-size:14px">&shy;</span>`;

export const NMCKEditor = (props) => {
  const { type, initialData, title, config, ...rest } = props
  return (
    <AppErrorBoundry>
      <CKEditor
        config={{ ...CKConfig, ...(config || {}) }}
        type={type}
        initData={initialData || InitialData}
        {...rest}
      />
    </AppErrorBoundry>
  )
}