import React from 'react';
import { useDispatch } from "react-redux";
import { FormDialog } from "../../components";
import { docActions } from "../../store";
import "./style.scss";

export const SimulationPublishDialog = (props) => {
  const dispatch = useDispatch()
  const handleSimulate = (e) => {
    dispatch(docActions.startAutoStatusPublishing())
    props.onClose && props.onClose()
  }
  return (
    <FormDialog
      onClose={props.onClose}
      title='Modify Document Status for Demo Purpose?'
      className="reset-trans-doc"
      titleClass="exo2 f7 cFE3333 bold"
      rightBtn={{
        label: 'Cancel',
        font: 'f8',
        onClick: props.onClose,
        autoFocus: true
      }}
      leftBtn={{
        label: 'Confirm',
        variant: 'lite',
        color: '#FE3333',
        font: 'f8',
        onClick: handleSimulate
      }}>
      <div className='col content '>
        <p className='f8 txt-justify'>This action will modify the Status for currently created documents to various possible options for the purpose of demonstrating how documents flow through various states from Draft till Publish.<br/><br/>
        This option is NOT available in actual Documenter!<br/>It is provided here ONLY for the purpose of quickly demonstrating this feature in Documenter.<br/><br/>
        Are you sure you want to continue?</p>
      </div>
    </FormDialog>
  )
}