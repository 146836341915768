const ActiveVariables = [
  { variable: '[[OrgnName]]' }, { variable: '[[OrgnNameAPST]]' }, { variable: '[[OrgnFullname]]' },
  { variable: '[[OrgnDomain]]' }, { variable: '[[OrgnWebsite]]' }, { variable: '[[OrgnCEOFullname]]' },
  { variable: '[[DcmtDept]]' }, { variable: '[[DcmtType]]' }, { variable: '[[DcmtStatus]]' },
  { variable: '[[DcmtID]]' }, { variable: '[[DcmtTitle]]' }, { variable: '[[DcmtFilename]]' },
  { variable: '[[DcmtVrsnContent]]' }, { variable: '[[DcmtVrsnPresentation]]' }, { variable: '[[DcmtContentPurpose]]' },
  { variable: '[[DcmtContentScope]]' }, { variable: '[[DcmtContentIntroduction]]' }, { variable: '[[DcmtContentDirective]]' },
  { variable: '[DcmtContentReference]]' }
];
export const getPrimaryEmailAddress = (org) => {
  let email = org.email_domain.split(',')[0]
  if (email) {
    email = email.replace('@', '')
  }
  return email;
}
export const performVariableMapping = (htmlContent, iOptions) => {
  let { org, document } = iOptions;
  document = document || {};
  org = org || {};
  let htmlContentCopy = String(htmlContent);
  ActiveVariables.forEach(({ variable }) => {
    let varHTML = '', val, addS;
    switch (variable) {
      case '[[OrgnName]]':
        val = org.brandname;
        // varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        varHTML = val
        break;
      case '[[OrgnNameAPST]]':
        val = String(org.brandname);
        addS = val.endsWith('s') || val.endsWith('se') || val.endsWith('sh') || val.endsWith('ce')
        val += "'"
        if (!addS) {
          val += "s"
        }
        varHTML = val
        // varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        break;
      case '[[OrgnFullname]]':
        val = String(org.legalname);
        val.replace('pvt', 'Private');
        val.replace('ltd', 'Limited');
        // varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        varHTML = val
        break;
      case '[[OrgnDomain]]':
        val = String(getPrimaryEmailAddress(org)).toLowerCase();
        varHTML = `@${val}`;
        break;
      case '[[OrgnWebsite]]':
        val = org.website;
        varHTML = `<a href="${val}" style="text-decoration:none">${val}</a>`;
        break;
      case '[[DcmtDept]]':
        val = document.department_name;
        varHTML = (typeof val === 'object') ? val.label : val;
        break;
      case '[[DcmtType]]':
        val = document.doc_type;
        val = (typeof val === 'object') ? val.label : val;
        varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        break;
      case '[[DcmtStatus]]':
        val = document.status;
        val = (typeof val === 'object') ? val.label : val;
        varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        break;
      case '[[DcmtID]]':
        varHTML = document.doc_id;
        break;
      case '[[DcmtTitle]]':
        varHTML = document.name;
        break;
      case '[[DcmtFilename]]':
        varHTML = `${document.doc_id}-${document.name}`;
        break;
      case '[[DcmtVrsnContent]]':
        varHTML = document.version;
        break;
      case '[[DcmtVrsnPresentation]]':
        varHTML = document.presentation ? document.presentation.version : '';
        break;
      case '[[DcmtContentPurpose]]':
        break;
      case '[[DcmtContentScope]]':
        break;
      case '[[DcmtContentIntroduction]]':
        break;
      case '[[DcmtContentDirective]]':
        break;
      case '[[DcmtContentReference]]':
        break;
      default:
        break;
    }
    if (varHTML) {
      htmlContentCopy = htmlContentCopy.replaceAll(variable, varHTML)
    }
  })
  return htmlContentCopy;
}