import { performVariableMapping } from "../VariableMapping";
export const Sections = [
  { title: "Document History", attribute: 'doc_history', defaultPage: 0, },
  { title: "Document Control", attribute: 'doc_control', defaultPage: 0, },
  { title: "Copyright Information", attribute: 'copyright', defaultPage: 0 },
  { title: "CONTENTS", attribute: 'tbl_of_content', defaultPage: 1, allowAddVariable: false },
  { title: "Purpose", attribute: 'purpose', defaultPage: 2, slNo: 1 },
  { title: "Scope", attribute: 'scope', defaultPage: 2, slNo: 2 },
  { title: "Organisation Identifier: ", attribute: 'organisation_identifier', defaultPage: 3, slNo: 3 },
  { title: "", attribute: 'common_doc_type', defaultPage: 3, slNo: 4 },
  { title: "Enforcement", attribute: 'enforcement', defaultPage: 4, slNo: 5 },
  { title: "Exception", attribute: 'exception', defaultPage: 4, slNo: 6 },
  { title: "Violation", attribute: 'violation', defaultPage: 4, slNo: 7 },
  { title: "Review", attribute: 'review', defaultPage: 5, slNo: 8 },
  { title: "Appendix", attribute: 'appendix', defaultPage: 5, slNo: 9 },
];
const ChangeHistory = { title: "Change History", attribute: 'change_history' };

const fillTableOfContents = (pages) => {
  var pageHTMLString = `<p>`, index;
  for (let i = 0; i < pages.length; i++) {
    for (let j = 0; j < pages[i].length; j++) {
      const section = pages[i][j];
      const { attribute, showSectionTitle } = section;
      if (attribute !== 'tbl_of_content') {
        if (showSectionTitle) {
          let isFirstSec = attribute === 'doc_history' || attribute === 'doc_control' || attribute === 'copyright';
          pageHTMLString += "<div class='w-100 row tbl-cont-row h-btn'>"
          pageHTMLString += `<a href='#section-${attribute}' class='f8 c0133CC text caps ${isFirstSec ? 'first' : 'bold'}'><span>${section.title}</span></a>`;
          pageHTMLString += `<a href='#page-${i + 1}' class='f8 c0133CC bold text page-no'><span>${i + 1}</span></a>`
          pageHTMLString += '</div>'
        }
      } else {
        if (!index) {
          index = i;
        }
      }
    }
  }
  pages[index][0].value = pageHTMLString;
}
const fillChangeHistory = (pages, document) => {
  //remove change History
  pages.forEach((page, i) => {
    page.forEach((section, j) => {
      if (section.attribute === 'change_history') {
        pages[i].splice(j, 1);
      }
    })
    if (pages[i].length === 0) {
      pages.splice(i, 1);
    }
  });
  // add change history
  if (document && Array.isArray(document.change_history) && document.change_history.length > 0) {
    let history = [...document.change_history], i = 0;
    while (i <= history.length) {
      let chunk = history.slice(i, 30)
      pages.push([{
        ...ChangeHistory,
        showSectionTitle: i === 0,
        value: chunk
      }])
      i += 30;
    }
  }
}
export const getDocumenterPages = (iOptions) => {
  const { document, user, isSchemaOnly } = iOptions || {}
  const presentation = document.presentation || {};
  let pageConfig = document.page_config ? JSON.parse(document.page_config) : null;
  let totalPages = pageConfig ? pageConfig.length : 6;
  let pageSections = [];
  const getSectionInfo = (attribute) => {
    let sectionInfo = Sections.find((_) => _.attribute === attribute)
    let title = '';
    if (sectionInfo) {
      title = sectionInfo.title;
    }
    if (attribute === 'common_doc_type') {
      title = isSchemaOnly ? '[[DocumentType]]' : document.doc_type.label;
    } else if (attribute === 'organisation_identifier') {
      title += ` "<span class="no-caps">${user.ent_org.brandname}</span>"`;
    } else if (attribute === ChangeHistory.attribute) {
      title = ChangeHistory.title;
    }
    return { title: title, slNo: sectionInfo ? sectionInfo.slNo : '' }
  }
  const getPresentationValue = (attribute) => {
    let value = presentation[attribute] || '';
    if (value) {
      if (presentation.page_config) {
        value = JSON.parse(value)
        value = Object.values(value).join()
      }
    }
    return value
  }
  for (let i = 0; i < totalPages; i++) {
    let sections = []
    let currentPageConfig = pageConfig && pageConfig[i];
    if (currentPageConfig) {
      for (let j = 0; j < currentPageConfig.length; j++) {
        const sectionConfig = currentPageConfig[j];
        if (sectionConfig) {
          const { attribute, showSectionTitle, key } = sectionConfig;
          let val = '';
          if (attribute === 'doc_history' || attribute === 'change_history') {
            val = document[attribute];
          } else {
            val = JSON.parse(document[attribute])
            val = (val && val[key]) ? val[key] : '';
            if ((typeof val === 'string') && val.length > 0) {
              val = performVariableMapping(val, { org: user.ent_org, document })
            }
            val = `<span style="font-family:Roboto;font-size:14px">${val || ''}</span>`
          }
          sections.push({ ...(getSectionInfo(attribute)), attribute, showSectionTitle, value: val })
        }
      }
      pageSections.push(sections);
    } else {
      if (i === 1) {
        pageSections.push([{ attribute: 'tbl_of_content', title: "CONTENTS", showSectionTitle: true, allowAddVariable: false }])
      } else {
        sections = Sections.filter((section) => section.defaultPage === i);
        sections = sections.map((_section) => {
          let section = { ..._section }, docValue = document[section.attribute],
            preVal = getPresentationValue(section.attribute), newVal = '';
          switch (section.attribute) {
            case 'copyright':
            case 'doc_control': newVal = preVal; break;
            case 'doc_history':
              newVal = docValue;
              break;
            default:
              if (section.attribute === 'organisation_identifier' && user) {
                section.title += ` "<span class="no-caps">${user.ent_org.brandname}</span>"`;
              } else if (section.attribute === 'common_doc_type') {
                section.title = isSchemaOnly ? '[[DocumentType]]' : document.doc_type.label;
              }
              if (preVal) {
                newVal += preVal;
              }
              if (docValue) {
                newVal += docValue;
              }
              break;
          };
          if (!isSchemaOnly && section.attribute !== 'doc_history') {
            newVal = `<span style="font-family:Roboto;font-size:14px">${newVal || ''}</span>`
            newVal = performVariableMapping(newVal, { org: user.ent_org, document })
          }
          return { ...section, showSectionTitle: true, value: newVal }
        });
        pageSections.push(sections);
      }
    }
  }
  fillChangeHistory(pageSections, document);
  fillTableOfContents(pageSections);
  return pageSections;
}