import React from 'react';
import Menu from '@mui/material/Menu';
import { DocumenterLogo, ProfilePic, CompanyLogo, AuditorIcon, ArcIcon, ConsoleIcon, ControllerIcon, EducatorIcon, InvestigatorIcon, CentralIcon, ContinuerIcon } from "../Assets";
import { useNavigate, Link } from "react-router-dom";
import "./style.scss";

const DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
const SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX || '';

const Modules = [
  { moduleId: 'auditor', Component: AuditorIcon, label: 'Auditor' },
  { moduleId: 'arc', Component: ArcIcon, label: 'Arc' },
  { moduleId: 'console', Component: ConsoleIcon, label: 'Console' },
  { moduleId: 'controller', Component: ControllerIcon, label: 'Controller' },
  { moduleId: 'educator', Component: EducatorIcon, label: 'Educator' },
  { moduleId: 'investigator', Component: InvestigatorIcon, label: 'Investigator' },
  { moduleId: 'central', Component: CentralIcon, label: 'Central' },
  { moduleId: 'continuer', Component: ContinuerIcon, label: 'Continuer' },
]
export const getModuleLink = (moduleId, user) => {
  const sessionToken = user.session_token;
  return `https://${moduleId}.${DOMAIN}/login?sessionToken=${sessionToken}`;
}
export const NavBar = (props) => {
  const { user, isAdmin, isArchitect } = props
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    let logoutPath = getModuleLink('console', user);
    logoutPath += '&redirect=logout'
    window.location.assign(logoutPath)
  }
  const getSubscribedModules = () => {
    if (isAdmin || isArchitect) {
      return Modules;
    }
    let modules = [...Modules];
    modules.splice(3, 1)
    return modules
  }
  return (
    <nav className='row w-100 nav-bar h-btn'>
      <DocumenterLogo className='logo' onClick={() => navigate('/')} />
      <div className='row'>
        <button className='btn row btn-profile no-border' onClick={handleClick}>
          <ProfilePic className='profile-img' />
          {/* <span className='f8 reg'>{user.firstname} {user.lastname}</span> */}
          <span className='f12'>
            <i className='c00085 icon-caret-down icon' />
          </span>
        </button>
        <CompanyLogo user={user} />
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <div className='col documenter-menus'>
          <div className='btn w-100 row btn-profile v-start no-border'>
            <ProfilePic className='profile-img' />
            <div className='col f-rest usename'>
              <div className='col w-100'>
                <span className='f7 reg'>{user.firstname} {user.lastname}</span>
                <span className='f10 reg c1155CC'>{user.email}</span>
              </div>
              <div className='row h-btn profile-signout w-100'>
                <Link to={getModuleLink('console', user) + '&redirect=profile'} target='_blank' className='f9 profile' >Profile</Link>
                <Link className='f9 logout' onClick={handleLogout}>Sign Out</Link>
              </div>
            </div>
          </div>
          {
            getSubscribedModules().map(({ label, Component, moduleId }) => {
              return (
                <a className='row v-ctr f9 menu-entry link c00085' rel="noreferrer" key={moduleId} href={getModuleLink(moduleId, user)} target="_blank" >
                  <Component className="mod-logo" />
                  <span>{label}</span>
                </a>
              )
            })
          }
        </div>
      </Menu>
    </nav>
  )
}
export const useNavigateBack = () => {
  const navigate = useNavigate();
  const navigateBack = () => {
    if (window.history && window.history.state) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }
  return navigateBack
}
